export enum eLevySummaryTypes {
  Financial_Area = 0,
  Financial_Group = 1,
  Property_Locality = 2,
  Electoral_Division = 3,
  Assessment_Type = 4,
  Assessment_Status = 5,
  Region = 6,
}

// For fist level grid
export interface DTO_ChargeRun_LevySummary {
  LevySummary: DTO_LevySummary[];
}

export interface DTO_LevySummary {
  GroupId: number | null;
  NumberOfLevies: number | null;
  BaseAmount: number | null;
  RateByValuationAmount: number | null;
  IncrementsToMinimum: number | null;
  GrossLevyAmount: number | null;
  Rebates: number | null;
  NettLevyAmount: number | null;
  AttributableValue: number | null;
  PostponedAmount: number | null;
  Group: string;
  AdjustmentForCapping: number | null;
  ReductionToMaximum: number | null;
  Valuation: number | null;
  Levy_Amount: number | null;
}
