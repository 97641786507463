import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface INewDebtRecoveryCommonButtonProps {
  isDisabled?: boolean;
  id?: number;
}

export const NewDebtRecoveryCommonButton = observer(
  ({ id, isDisabled }: INewDebtRecoveryCommonButtonProps) => {
    const { currentOrganisationMode } = useCommonProductStore();
    const isLLS = currentOrganisationMode(OrganisationMode.LLS);
    const isGeneral = currentOrganisationMode(OrganisationMode.General);

    const { setListDialog } = usePropertyWorkflowStore();
    const idManagePage = useParams<{ id: string }>();

    const isHidden = useMemo(() => {
      return isLLS || isGeneral;
    }, [isGeneral, isLLS]);

    const recordID = id ?? +idManagePage?.id;

    if (isHidden) return null;
    return (
      <CCNavButton
        title="New debt recovery"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Create_DebtRecovery,
              data: { assessmentID: recordID },
            },
          ]);
        }}
        disabled={isDisabled}
      />
    );
  }
);
