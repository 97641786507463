import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataChangeOfOwnership,
  postProcessChangeOfOwnership,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/api";
import { LeaseFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/lease/_index";
import { NamesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/_index";
import { RebatesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/rebates/_index";
import { TypeFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/_index";
import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Entity_Details,
  DTO_RebateEntitlementOwners,
  DTO_Rebates,
  DTO_Workflow_ChangeOfOwnership,
  EKeysOfSteps,
  keysOfSendSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { useChangeOfOwnershipDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/store";
import { OfficerReallocateDialog } from "@app/products/property/assessments/components/form-steps/modify-assessment/dialog/officer-reallocate/_index";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";

import { DetailsFormStep } from "@app/products/property/changes-of-ownership/lease-transfers/components/form-steps/update-lease-transfers/components/form-elements/detail/_index";
import { PropertyFormStep } from "@app/products/property/changes-of-ownership/lease-transfers/components/form-steps/update-lease-transfers/components/form-elements/property/_index";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { eComponent } from "@app/products/property/components/associations/model";
import { VotingFormStep } from "@app/products/property/contacts-central-names/list/components/dialogs/components/form-elements/voting/_index";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { OrganisationMode } from "@common/stores/products/model";
import { useCommonProductStore } from "@common/stores/products/store";
import { getDropdownValue } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
interface IChangeOfOwnershipDialogProps {
  assessmentId: number;
  picId: number;
  contactId: number;
  titleId: number;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  onReject?: (data: any) => void;
  onClose: () => void;
  onSubmit: (data: any) => void;
  handleReallocate?: (data: any) => void;
}
const defaultTitle = "Update Lease Transfer";

export const UpdateLeaseTransferDialog = observer(
  (props: IChangeOfOwnershipDialogProps) => {
    const {
      assessmentId,
      picId,
      contactId,
      titleId,
      prefixTitle,
      suffixTitle,
      onClose,
      handleReallocate,
      dataFromActionList,
      // TODO: Use after have api
      // isSaveOnNextStep = false,
    } = props;

    //#region HOOKS -------------------------------------------->
    const { currentOrganisationMode, currentFormTitle } =
      useCommonProductStore();
    const isActro = currentOrganisationMode(OrganisationMode.ACTRO);
    const { changeOfOwnershipLOVs, setChangeOfOwnershipLOVs } =
      useChangeOfOwnershipDialogStore();
    const { setDataForRejectDialog } = useConfirmRejectDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
    const { pushNotification } = useCCAppNotificationStore();

    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [changeOfOwnershipInitData, setChangeOfOwnershipInitData] =
      useState<DTO_Workflow_ChangeOfOwnership>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
    const [isShowOfficerReallocate, setIsShowOfficerReallocate] =
      useState<boolean>(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [selectedTypeId, setSelectedTypeId] = useState<number>();
    const {
      isIncompleteMode,
      isFromActionList,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isBeingApprovedMode,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);
    //#endregion <--------------------------------------------

    //#region INITIALVALUE -------------------------------------------->
    const initialValues = useMemo(() => {
      let initType: any = { _option: { Type: undefined } };
      let initDetails: any = {};
      let initProperty: any = { _option: { AssessmentSelected: undefined } };
      let initNames: {
        Contacts: DTO_Entity_Details[];
      } = {
        Contacts: [],
      };
      let initRatePayer: {
        Contacts: DTO_Entity_Details[];
      } = {
        Contacts: [],
      };
      let initDuplicateRatePayer: {
        Contacts: DTO_Entity_Details[];
        Name: string;
      } = {
        Contacts: [],
        Name: "",
      };

      let initRebates: {
        Rebates: DTO_Rebates[];
        Owners: DTO_RebateEntitlementOwners[];
      } = {
        Rebates: [],
        Owners: [],
      };
      if (changeOfOwnershipInitData) {
        const workflowDetail = changeOfOwnershipInitData?.WorkflowDetail;
        const assessmentSelected =
          workflowDetail?.PropertyDetails?.Assessments?.find(
            (item) => item?.Assessment_Id === +assessmentId
          );
        const typeSelectedRow =
          changeOfOwnershipLOVs?.ChangeOfOwnershipTypes?.find(
            (typeItem) =>
              typeItem?.Change_of_Ownership_Type ===
              workflowDetail?.COODetails?.Type
          );
        initType = {
          ...initType,
          ...workflowDetail?.COODetails,
          _option: {
            Type: typeSelectedRow,
          },
        };

        initDetails = { ...initDetails, ...workflowDetail?.COODetails };
        initNames = {
          ...initNames,
          ...workflowDetail?.OwnerDetails,
        };
        initProperty = {
          ...initProperty,
          ...workflowDetail?.PropertyDetails,
          _option: { AssessmentSelected: assessmentSelected },
        };
        initRatePayer = {
          ...initRatePayer,
          ...workflowDetail?.RatePayerDetails,
        };
        initDuplicateRatePayer = {
          ...initDuplicateRatePayer,
          ...workflowDetail?.DuplicateRatePayerDetails,
        };

        initRebates = {
          ...initRebates,
          ...workflowDetail?.RebateEntitlements,
        };

        notificationFormStepRef?.current?.setStepsVisible([
          {
            visible:
              initType?._option?.Type?.COOT_Display_Rebates &&
              initRebates?.Owners?.length !== 0,
            key: EKeysOfSteps.Rebates,
            isClearData: false,
          },
        ]);
      }

      return {
        [EKeysOfSteps.Type]: initType,
        [EKeysOfSteps.Details]: initDetails,
        [EKeysOfSteps.Property]: initProperty,
        [EKeysOfSteps.Names]: initNames,
        [EKeysOfSteps.RatePayer]: initRatePayer,
        [EKeysOfSteps.DuplicateRatePayer]: initDuplicateRatePayer,
        [EKeysOfSteps.Rebates]: initRebates,
        [EKeysOfSteps.Voting]: [],
        [EKeysOfSteps.Comments]: {},
        [EKeysOfSteps.Documents]: {},
      };
    }, [changeOfOwnershipInitData, changeOfOwnershipLOVs, assessmentId]);
    //#endregion <--------------------------------------------
    const steps: IStep[] = [
      {
        label: "Type",
        initialValues: initialValues[EKeysOfSteps.Type],
        component: TypeFormStep,
        visible: true,
        key: EKeysOfSteps.Type,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Details",
        initialValues: initialValues[EKeysOfSteps.Details],
        component: DetailsFormStep,
        visible: true,
        key: EKeysOfSteps.Details,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Property",
        initialValues: initialValues[EKeysOfSteps.Property],
        component: PropertyFormStep,
        visible: true,
        key: EKeysOfSteps.Property,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Names",
        initialValues: initialValues[EKeysOfSteps.Names],
        component: NamesFormStep,
        visible: true,
        key: EKeysOfSteps.Names,
        options: {
          isReadOnly,
        },
      },
      //TODO: Implement in the future outside ACTRO
      {
        label: "Lease",
        initialValues: {},
        component: LeaseFormStep,
        visible: !isActro,
        key: "Lease",
        options: {
          isReadOnly,
        },
      },
      {
        label: "Rebates",
        initialValues: initialValues[EKeysOfSteps.Rebates],
        component: RebatesFormStep,
        visible: false,
        key: EKeysOfSteps.Rebates,
        options: {
          isReadOnly,
        },
      },
      {
        label: "Voting",
        initialValues: initialValues[EKeysOfSteps.Voting],
        component: VotingFormStep,
        visible: !isActro,
        key: EKeysOfSteps.Voting,
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        initialValues: initialValues[EKeysOfSteps.Documents],
        visible: true,
        key: EKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Change_Of_Ownership,
          subType: selectedTypeId,
        },
      },
      {
        label: "Comments",
        initialValues: initialValues[EKeysOfSteps.Comments],
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Change_of_Ownership,
        },
      },
    ];

    const [titleHeader, setTitleHeader] = useState<string>(defaultTitle);
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Approve:
          await handleApproveProcess(processData(data));
          break;
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          handleConfirmFinishProcess(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.Reject:
          handleRejectButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.SendBack:
          handleConfirmSendBackProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    const fullTitleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || titleHeader;

      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleHeader, prefixTitle, suffixTitle, workflowHeader]);

    // TODO: Use after have api
    // const handleNextButton = async (data: any) => {
    //   setIsLoadingOnNext(true);
    //   return await handleSaveAndNext(processData(data));
    // };

    /**
     * Handle save
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_ChangeOfOwnership,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      //check condition use for Save button
      isCloseDialog && setIsLoadingSave(true);

      //Calling process Save at next button
      const response = await postProcessChangeOfOwnership(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);

      //set default notification
      const defaultSuccessMessage =
        "Update lease transfer was saved successfully.";
      const defaultFailedMessage = "Update lease transfer could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          setIsLoadingSave(false);
          onClose();
          pushNotification({
            title:
              response?.data?.Notification ??
              response?.data?.SuccessMessage ??
              defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID || 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID || workflowDraftId || 0,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };

    /**
     * Handle cancel process
     */
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessChangeOfOwnership,
          dataCancel: data,
          defaultSuccessMessage:
            "Update lease transfer was cancelled successfully.",
          defaultErrorMessage: "Update lease transfer could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    /**
     * Handle reject process
     */
    const handleRejectButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForRejectDialog({
          rejectCallback: postProcessChangeOfOwnership,
          dataReject: data,
          defaultSuccessMessage:
            "Update lease transfer was rejected successfully.",
          defaultErrorMessage: "Update lease transfer could not be rejected.",
        });
      } else {
        onClose();
      }
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Update lease transfer was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Update lease transfer could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle confirm send back workflow process
     * @param payload
     */
    const handleConfirmSendBackProcess = (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      setDataForSendBackDialog({
        sendBackCallback: postProcessChangeOfOwnership,
        dataSendBack: payload,
        defaultSuccessMessage:
          "Update lease transfer was sent back successfully.",
        defaultErrorMessage: "Update lease transfer could not be sent back",
      });
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Update lease transfer was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Update lease transfer could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => setIsLoadingPark(false);

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership>,
      setLoading: () => void
    ) => {
      const { actionSuccess } = props;
      setLoading();
      actionSuccess({});

      // TODO: Use after have api
      // const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
      //   props;
      // const response = await postProcessChangeOfOwnership(modeProcess, payload);

      // setLoading();
      // if (isSuccessResponse(response)) {
      //   if (response?.data?.IsSuccess) {
      // actionSuccess(response?.data);
      //     } else {
      //       notificationFormStepRef?.current
      //         ?.getNotificationFormStep()
      //         ?.current?.pushNotification({
      //           title: response.data?.ErrorMessage ?? defaultFailedMessage,
      //           type: "error",
      //           autoClose: false,
      //         });
      //     }
      //   } else {
      //     notificationFormStepRef?.current
      //       ?.getNotificationFormStep()
      //       ?.current?.pushNotification({
      //         title: response?.data?.ErrorMessage ?? defaultFailedMessage,
      //         type: "error",
      //         autoClose: false,
      //       });
      //   }
    };

    /**
     * handle approve process
     */
    const handleApproveProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //set loading button
      setIsLoadingApprove(true);

      //props send to process workflow
      const approveProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Update lease transfer was approved successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Update lease transfer could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };

      const setLoading = () => {
        setIsLoadingApprove(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(approveProps, setLoading);
    };

    const getWorkflowData = () => {
      //Update later when have api
      // const id = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      //Use this API as mock
      getInitialDataChangeOfOwnership(
        assessmentId,
        contactId,
        titleId,
        picId,
        eComponent.Assessment
      ).then((response) => {
        if (Array.isArray(response)) {
          const [lovsChangeOfOwnershipResponse, workflowDataResponse] =
            response;
          const lovsChangeOfOwnershipData = lovsChangeOfOwnershipResponse?.data;
          const workflowData = workflowDataResponse?.data;
          if (
            isSuccessResponse(response[0]) &&
            isSuccessResponse(response[1]) &&
            lovsChangeOfOwnershipData &&
            workflowData
          ) {
            //set Lovs Data ChangeOfOwnership
            setChangeOfOwnershipLOVs(lovsChangeOfOwnershipData);
            //set Modify ChangeOfOwnership InitData
            setChangeOfOwnershipInitData({
              WorkflowDetail: workflowData?.WorkflowDetail,
              WorkflowHeader: workflowData?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.WorkflowHeader) {
              setWorkflowHeader(workflowData.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
          } else {
            let responseError: APIResponse<
              | DTO_ChangeOfOwnership_LOVs
              | DTO_Workflow_ChangeOfOwnership
              | ResponsePacket
            > = response[0];
            if (!isSuccessResponse(response[1])) {
              responseError = response[1];
            }
            notificationFormStepRef?.current?.setLoadingFormStep(false);
            notificationFormStepRef?.current?.setLoadFailedFormStep({
              onReload: () => getWorkflowData(),
              responseError: {
                status: responseError.status,
                error:
                  (responseError.data as ResponsePacket)?.Errors ??
                  "Load workflow failed",
              },
            });
          }
        } else {
          const responseError = response as APIResponse;
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      });
    };

    /**
     * handle finish workflow process
     * @param payload
     */
    const handleConfirmFinishProcess = (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The update lease transfer will be created based on the information provided. Are you sure you want to submit?",
      });
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_ChangeOfOwnership
    ) => {
      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_ChangeOfOwnership> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              "Update lease transfer was created successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Update lease transfer could not be created.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {};
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };

    const processData = (data: any) => {
      let workflowDetail: any = {};
      data[EKeysOfSteps.Details].Type = data[EKeysOfSteps.Type].Type;
      const sendSteps = pickBy(data, function (value, key) {
        if (keysOfSendSteps.includes(key as EKeysOfSteps)) {
          return { [key]: value };
        }
      });

      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep && dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key as keyof DTO_Workflow_ChangeOfOwnership] = dataStep;
      }
      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          listButtonId={listSubmitButton}
          onSubmit={handleSubmit}
          onValueChange={(values) => {
            if (values[EKeysOfSteps.Type]?.Type !== selectedTypeId) {
              const typeName = getDropdownValue(
                values[EKeysOfSteps.Type]?.Type,
                changeOfOwnershipLOVs?.ChangeOfOwnershipTypes ?? [],
                "Change_of_Ownership_Type"
              )?.COOT_Name;
              setTitleHeader(defaultTitle + (typeName ? " - " + typeName : ""));
              setSelectedTypeId(values[EKeysOfSteps.Type]?.Type);
            }
          }}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={undefined}
          //TODO: Use after have api
          // saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
          renderForm={(renderProps: ICCFormStepRender) => (
            <CCDialog
              maxWidth="60%"
              titleHeader={fullTitleHeader}
              badge={statusBadge}
              onClose={onClose}
              bodyElement={renderProps.children}
              footerElement={
                <>
                  <div className={"cc-dialog-footer-actions-right"}>
                    {isShowParkButton(isFromActionList, isIncompleteMode) && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!isReadOnly &&
                      isToBeApprovalMode &&
                      !isBeingApprovedMode && (
                        <Button
                          iconClass={
                            isLoadingSave ? "fas fa-spinner fa-spin" : ""
                          }
                          disabled={
                            renderProps.nextButton.disabled || isLoadingSave
                          }
                          className={"cc-dialog-button"}
                          id={EListSubmitButton.Save}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Save
                        </Button>
                      )}
                    {isShowCancelWorkflowButton && (
                      <Button
                        className={"cc-dialog-button"}
                        disabled={
                          isLoadingApprove || isLoadingOnNext || isLoadingPark
                        }
                        id={EListSubmitButton.Cancel}
                        onClick={renderProps.submitButton.onClick}
                      >
                        Cancel
                      </Button>
                    )}

                    {isToBeApprovalMode && !isBeingApprovedMode && (
                      <>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.SendBack}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Send Back
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reallocate}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={() => setIsShowOfficerReallocate(true)}
                        >
                          Reallocate
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Approve}
                          disabled={
                            isLoadingApprove || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                          iconClass={
                            isLoadingApprove ? "fas fa-spinner fa-spin" : ""
                          }
                        >
                          Approve
                        </Button>
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isToBeApprovalMode || isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                </>
              }
            />
          )}
        />

        {isShowOfficerReallocate && (
          <OfficerReallocateDialog
            onClose={() => {
              setIsShowOfficerReallocate(false);
            }}
            onSubmit={(data) => {
              if (handleReallocate) {
                handleReallocate(data);
              }
            }}
          />
        )}
      </>
    );
  }
);
