import { loadViewConfiguresColumns } from "@app/products/property/api";
import { colAssessmentProgeny } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/config";
import { AddLotsDialog } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/dialogs/add-lots/_index";
import { getSubdivisionProgenyLovs } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/dialogs/add-lots/api";
import { validatorProgenySubdivide } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/components/form-elements/progeny/util";
import {
  DTO_Progeny,
  EKeysOfStepsSubdivideConsolidate,
} from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/model";
import { useSubdivideConsolidateDialogStore } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/store";
import { calculateTotalLandArea } from "@app/products/property/assessments/components/form-steps/subdivide-consolidate/util";
import { ViewConfiguration, nameOfLov } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DECIMAL_FORMAT } from "@common/constants/common-format";
import { getDropdownValue, getUUID, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const SubdivideConsolidateProgenyFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={
        !props?.options?.isReadOnly ? validatorProgenySubdivide : undefined
      }
    />
  );
};

const nameOfProgeny = nameOfFactory<DTO_Progeny>();
const FormStepElement = observer(
  ({
    nameOf,
    options = { isReadOnly: false, customTitleLabel: "lot" },
    formRenderProps,
    isLoadingStep,
    setIsLoadingStep = () => {},
    loadFailedStep,
    setLoadFailedStep = () => {},
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const progenies = getFieldValue("Progenies") ?? [];

    //Use state
    const [isShowAddLotsDialog, setIsShowAddLotsDialog] =
      useState<boolean>(false);
    const { progenyLOVs, setProgenyLOVs } =
      useSubdivideConsolidateDialogStore();

    const [processedCol, setProcessedCol] = useState<IColumnFields[]>([]);

    //Use effect
    const loadConfigAndLOVs = async () => {
      const parentTitleIds = valueGetter(
        `${EKeysOfStepsSubdivideConsolidate.Details}.Titles`
      );
      if (!parentTitleIds.length) return;
      setLoadFailedStep(undefined);
      setIsLoadingStep(true);
      const response = await Promise.all([
        loadViewConfiguresColumns(
          ViewConfiguration.Assessment_Subdivision_Progeny,
          colAssessmentProgeny
        ),
        getSubdivisionProgenyLovs(parentTitleIds),
      ]);
      setIsLoadingStep(false);
      if (Array.isArray(response)) {
        const [responseViewConfig, responseLOVs] = response;
        const dataLOVs = responseLOVs?.data;
        if (
          Array.isArray(responseViewConfig) &&
          isSuccessResponse(responseLOVs) &&
          dataLOVs
        ) {
          setProcessedCol(responseViewConfig);
          setProgenyLOVs(dataLOVs);
        } else {
          let responseError = responseViewConfig as APIResponseError;
          if (!isSuccessResponse(responseLOVs) || !dataLOVs) {
            responseError = responseLOVs;
          }

          setLoadFailedStep({
            onReload: () => {
              loadConfigAndLOVs();
            },
            responseError: {
              status: responseError.status,
              error: responseError.error ?? "Load failed",
            },
          });
        }
      }
    };

    useEffectOnce(() => {
      loadConfigAndLOVs();
    });

    if (isLoadingStep) {
      return <Loading isLoading={isLoadingStep} />;
    }

    if (loadFailedStep) {
      return (
        <CCLoadFailed
          onReload={loadFailedStep?.onReload}
          responseError={loadFailedStep?.responseError}
        />
      );
    }

    const handleAddLotsSubmit = (event: DTO_Progeny) => {
      const newProgenyGridData = [];
      const lotFrom = event.Lot_From;
      const lotTo = event.Lot_To ?? event.Lot_From;
      const buildingUnitType = event.House_Number;

      for (let i = 0; i <= lotTo - lotFrom; i++) {
        let houseNumber = event.House_Number ? +event.House_Number : null;
        let unitNumber = event.Building_Unit_Number
          ? +event.Building_Unit_Number
          : null;
        if (houseNumber && (!unitNumber || buildingUnitType)) {
          houseNumber += i;
        } else if (unitNumber && (!houseNumber || !buildingUnitType)) {
          unitNumber += i;
        }

        newProgenyGridData.push({
          ...event,
          RowId: getUUID(),
          Lot: (lotFrom + i).toString(),
          House_Number: houseNumber,
          House_Number_1: houseNumber,
          Building_Unit_Number: unitNumber,
          Building_Unit_Name: event.Building_Unit_Name,
          Street_And_Locality: event.Street_And_Locality,
          Street_Locality_Id: event.Street_Locality_Id,
        });
      }

      onChange(nameOf("Progenies"), {
        value: newProgenyGridData,
      });
      onChange(nameOf("AreaOfProgenyTitles"), { value: 0 });
    };

    const handleEditProgenyData = (data: DTO_Progeny[]) => {
      const newProgenies = data.map((item: DTO_Progeny) => {
        return {
          ...item,
          Street_Locality_Id:
            getDropdownValue(
              item.Street_And_Locality,
              progenyLOVs?.StreetLocality ?? [],
              nameOfLov("Name")
            )?.Code ?? null,
        };
      });
      onChange(nameOf("Progenies"), { value: newProgenies });
      onChange(nameOf("AreaOfProgenyTitles"), {
        value: calculateTotalLandArea(data, "Land_Area"),
      });
    };
    return (
      <section className="cc-field-group">
        <div className="cc-field">
          <CCLabel title="Progeny" isMandatory />
          {errors?.[nameOf("")] ? <Error>{errors[nameOf("")]}</Error> : null}
          <CCGrid
            data={progenies}
            columnFields={processedCol}
            primaryField={nameOfProgeny("RowId")}
            editableMode="row"
            onDataChange={(data: DTO_Progeny[]) => {
              handleEditProgenyData(data);
            }}
            toolbar={
              <div className="cc-grid-tools-bar">
                <Button
                  iconClass="fas fa-plus"
                  title="Add Lots"
                  onClick={() => {
                    setIsShowAddLotsDialog(true);
                  }}
                  disabled={options?.isReadOnly}
                />
              </div>
            }
            readOnly={options?.isReadOnly}
          />
          {isShowAddLotsDialog && (
            <AddLotsDialog
              progenyLOVs={progenyLOVs}
              onClose={() => {
                setIsShowAddLotsDialog(false);
              }}
              onSubmit={(event: any) => {
                handleAddLotsSubmit(event);
                setIsShowAddLotsDialog(false);
              }}
            />
          )}
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">
              Area of parent {`${options?.customTitleLabel ?? "lot"}(s)`}
            </label>
            <div className="cc-custom-input-group">
              <Field
                name={nameOf("AreaOfParentTitles")}
                component={CCNumericTextBox}
                spinners={false}
                format={DECIMAL_FORMAT.DECIMAL1}
                readOnly
              />
              <div className="cc-input-group-postfix">
                <p>
                  m<sup>2</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <label className="cc-label">Area of progeny</label>
            <div className="cc-custom-input-group">
              <Field
                name={nameOf("AreaOfProgenyTitles")}
                component={CCNumericTextBox}
                spinners={false}
                format={DECIMAL_FORMAT.DECIMAL1}
                readOnly
              />
              <div className="cc-input-group-postfix">
                <p>
                  m<sup>2</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
