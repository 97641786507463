import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import {
  getInitialDataAdjustCharge,
  postProcessAdjustCharge,
  RequestJournalAssessmentAdjustChargeObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/api";
import { AdjustChargesFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-charges/_index";
import { AdjustTransactionFormStep } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/components/steps/adjust-transaction/_index";
import { adjustChargeKeysOfSendSteps } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/config";
import {
  DTO_Workflow_JournalAssessmentAdjustCharge,
  EKeysOfStepsAdjustCharge,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/model";
import { useAdjustChargeDialogStore } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charges/store";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { NewSupplementaryFormStep } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/_index";
import { useNewSupplementaryDialogStore } from "@app/products/property/assessments/master-properties/[id]/components/forms/existed/components/form-steps/add-to-suplementary/components/form-elements/new-supplementary/store";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { SupplementaryMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import {
  getWorkflowOverwrittenAtAuthorisingOfficer,
  omitOfficerBasedOnStatus,
} from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/util";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { Button } from "@progress/kendo-react-buttons";
import { isNil, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IAdjustChargeDialog {
  onClose: () => void;
  onSubmit: (data: any) => void;
  adjustChargeData?: any[];
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  handleReallocate?: (data: any) => void;
  adjustChargeGetNewObj?: RequestJournalAssessmentAdjustChargeObj;
  isRedirectManagePage?: boolean;
}
export default function AdjustChargeDialog(props: IAdjustChargeDialog) {
  //Props
  const {
    onClose,
    isSaveOnNextStep = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    adjustChargeGetNewObj = {
      AssessmentId: 0,
      TransactionId: null,
      Charge_Ids: [],
    },
    isRedirectManagePage = true,
  } = props;
  const history = useHistory();

  //State
  const notificationFormStepRef = useRef<ICCFormStepNotificationHandle | null>(
    null
  );
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const [adjustChargeInitData, setAdjustChargeInitData] = useState<
    DTO_Workflow_JournalAssessmentAdjustCharge | undefined
  >();
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

  //Store
  const { currentFormTitle } = useCommonProductStore();
  const { setAdjustChargeLOVs } = useAdjustChargeDialogStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { setNewJournalStepLOVs } = useNewJournalStepStore();
  const { setCreateSupplementaryLOVs } = useNewSupplementaryDialogStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { clearSelectedItems } = useCCProductListViewStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const {
    isIncompleteMode,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
    isBeingApprovedMode,
    isFromActionList,
  } = usePropertyWorkflow(dataFromActionList);

  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title = currentFormTitle(formId ?? 0) || "Adjust charges";

    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, workflowHeader]);

  const assessmentId = useMemo(() => {
    return adjustChargeInitData?.WorkflowDetail?.Assessment_Id ?? 0;
  }, [adjustChargeInitData]);

  const initialValue = useMemo(() => {
    //Step 1: Which Journal
    const journalNumber = adjustChargeInitData?.WorkflowDetail?.Journal_Number;
    const initWhichJournal: any = {
      JournalMode: adjustChargeInitData?.WorkflowDetail?.JournalMode,
      JournalSelected: journalNumber ? [{ Journal_Number: journalNumber }] : [],
    };
    //Step 2: Create Journal
    const initNewJournal: any = {
      ...adjustChargeInitData?.WorkflowDetail?.CreateJournal,
      Journal_Type: 0,
      SupplementaryMode:
        adjustChargeInitData?.WorkflowDetail?.SupplementaryMode,
    };
    const supplementaryId =
      adjustChargeInitData?.WorkflowDetail?.CreateJournal?.Supplementary_Id;
    if (supplementaryId) {
      initNewJournal._options = {
        SupplementarySelected: [
          {
            Supplementary_Id: supplementaryId,
          },
        ],
      };
    }
    //Step 3: New Supplementary
    const initNewSupplementary: any = {
      ...adjustChargeInitData?.WorkflowDetail?.CreateSupplementary,
    };
    //Step 4: Adjust Charges
    const initAdjustCharge: any[] =
      adjustChargeInitData?.WorkflowDetail?.AssessmentCharges ?? [];
    //Step 5: Transaction Details
    const transactionDetails =
      adjustChargeInitData?.WorkflowDetail?.TransactionDetails;
    const initTransactionDetails: any = {
      ...transactionDetails,
      TransactionDateTime:
        transactionDetails?.TransactionDateTime ?? new Date(),
      TransactionDescription: transactionDetails?.TransactionDescription ?? "",
      Transaction_Type: transactionDetails?.Transaction_Type || undefined,
    };
    //Show-hide steps
    notificationFormStepRef?.current?.setStepsVisible([
      {
        key: EKeysOfStepsAdjustCharge.NewJournal,
        visible:
          adjustChargeInitData?.WorkflowDetail.JournalMode ===
          JournalMode.CreateNew,
        isClearData: false,
      },
      {
        key: EKeysOfStepsAdjustCharge.NewSupplementary,
        visible:
          adjustChargeInitData?.WorkflowDetail.SupplementaryMode ===
          SupplementaryMode.CreateNew,
        isClearData: false,
      },
    ]);

    return {
      [EKeysOfStepsAdjustCharge.WhichJournal]: initWhichJournal,
      [EKeysOfStepsAdjustCharge.NewJournal]: initNewJournal,
      [EKeysOfStepsAdjustCharge.NewSupplementary]: initNewSupplementary,
      [EKeysOfStepsAdjustCharge.AdjustCharges]: initAdjustCharge,
      [EKeysOfStepsAdjustCharge.TransactionDetails]: initTransactionDetails,
      [EKeysOfStepsAdjustCharge.Documents]: {},
      [EKeysOfStepsAdjustCharge.Comments]: {},
    };
  }, [adjustChargeInitData]);

  const steps: IStep[] = [
    {
      label: "Journal",
      initialValues: initialValue[EKeysOfStepsAdjustCharge.WhichJournal],
      component: WhichJournalFormStep,
      visible: true,
      key: EKeysOfStepsAdjustCharge.WhichJournal,
      options: {
        showBringUpField: false,
        isReadOnly,
        newJournalKey: EKeysOfStepsAdjustCharge.NewJournal,
        newSupplementary: EKeysOfStepsAdjustCharge.NewSupplementary,
      },
    },
    {
      label: "New journal",
      component: NewJournalFormStep,
      visible: true,
      key: EKeysOfStepsAdjustCharge.NewJournal,
      options: {
        showStatusField: true,
        isReadOnly,
        showWhichSupplementary: true,
        supplementaryStepKey: EKeysOfStepsAdjustCharge.NewSupplementary,
        officerNameField: "Officer_Id",
        isDisableJournalType: true,
      },
      initialValues: initialValue[EKeysOfStepsAdjustCharge.NewJournal],
    },
    {
      label: "New supplementary",
      component: NewSupplementaryFormStep,
      visible: false,
      key: EKeysOfStepsAdjustCharge.NewSupplementary,
      initialValues: initialValue[EKeysOfStepsAdjustCharge.NewSupplementary],
      options: {
        isReadOnly,
      },
    },
    {
      label: "Adjust charges",
      component: AdjustChargesFormStep,
      visible: true,
      key: EKeysOfStepsAdjustCharge.AdjustCharges,
      initialValues: initialValue[EKeysOfStepsAdjustCharge.AdjustCharges],
      options: {
        isReadOnly,
        assessmentId: assessmentId,
      },
    },
    {
      label: "Transaction",
      component: AdjustTransactionFormStep,
      visible: true,
      key: EKeysOfStepsAdjustCharge.TransactionDetails,
      initialValues: initialValue[EKeysOfStepsAdjustCharge.TransactionDetails],
      options: {
        isReadOnly,
      },
    },
    {
      label: "Comments",
      initialValues: initialValue[EKeysOfStepsAdjustCharge.Comments],
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfStepsAdjustCharge.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        workflowDraftId,
        isReadOnly,
        recordType: RECORDTYPE.CommunityProperty_Journal,
      },
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      initialValues: initialValue[EKeysOfStepsAdjustCharge.Documents],
      visible: true,
      key: EKeysOfStepsAdjustCharge.Documents,
      options: {
        workflowDraftId,
        isReadOnly,
        workflowType: WorkflowTypes.Journal_Assessment_Adjust_Charge,
      },
    },
  ];

  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Approve:
        await handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.Save:
        await handleSaveAndNext(processData(data), true);
        break;
      case EListSubmitButton.Finish:
        handleConfirmFinishProcess(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleConfirmRetainProcess(processData(data));
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
    }
  };

  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = () => {
    const id = dataFromActionList?.Workflow_Draft_Id;
    const willBeNewWorkflow = isNil(id);
    notificationFormStepRef?.current?.setLoadingFormStep(true);
    getInitialDataAdjustCharge(id, adjustChargeGetNewObj).then((response) => {
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set LOVs Data
          setAdjustChargeLOVs(lovsJournal?.data);
          if (lovsJournal?.data?.JournalTypes) {
            setNewJournalStepLOVs({
              JournalTypes: convertValueLOVToNumber(
                lovsJournal?.data?.JournalTypes,
                "Code"
              ),
              Officer: convertValueLOVToNumber(
                lovsJournal?.data?.Officer,
                "Code"
              ),
            });
          }
          const createSupplementaryLOVs: any = {
            SupplementaryType: lovsJournal?.data?.SupplementaryType ?? [],
            InstallmentType: lovsJournal?.data?.InstalmentPlans,
            AssessmentType: lovsJournal?.data?.AssessmentType,
            ValuationTypeTobeUsed: lovsJournal?.data?.ValuationTypeTobeUsed,
          };
          setCreateSupplementaryLOVs(createSupplementaryLOVs);
          setAdjustChargeInitData(
            willBeNewWorkflow
              ? getWorkflowOverwrittenAtAuthorisingOfficer(workflowData?.data)
              : workflowData?.data
          );
          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ??
                workflowDraftId ??
                0
            );
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_Journal_LOVs
            | DTO_Workflow_JournalAssessmentAdjustCharge
            | ResponsePacket
          > = lovsJournal;
          if (!isSuccessResponse(workflowData)) {
            responseError = workflowData;
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    });
  };

  useEffectOnce(() => {
    getWorkflowData();
  });

  /**
   * handle save
   * @param payload
   * @param isCloseDialog
   * @returns Promise<boolean>
   */
  const handleSaveAndNext = async (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge,
    isCloseDialog: boolean = false
  ): Promise<boolean> => {
    //check condition use for Save button
    isCloseDialog && setIsLoadingSave(true);

    //Calling process Save at next button
    const response = await postProcessAdjustCharge(
      WorkflowProcessMode.Save,
      payload
    );

    setIsLoadingOnNext(false);

    //set default notification
    const defaultSuccessMessage =
      "Assessment adjust charge was saved successfully.";
    const defaultFailedMessage = "Assessment adjust charge could not be saved.";

    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (isCloseDialog) {
        setIsLoadingSave(false);
        onClose();
        pushNotification({
          title:
            response?.data?.Notification ??
            response?.data?.SuccessMessage ??
            defaultSuccessMessage,
          type: "success",
        });
      }
      // check is the first saving
      if (isFirstSave) {
        setIsFirstSave(false);
        //set current workflowDraft Id
        setWorkflowDraftId(response?.data?.ID || 0);
        // set payload to send
        setWorkflowHeader({
          ...workflowHeader,
          WorkflowDraft: {
            ...workflowHeader.WorkflowDraft,
            Workflow_Draft_Id: response?.data?.ID ?? workflowDraftId ?? 0,
          },
        });
      }
      return true;
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
        });
      return false;
    }
  };

  /**
   * handle park process
   * @param payload
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge
  ) => {
    //set loading button and dialog
    setIsLoadingPark(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge> =
      {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Assessment adjust charge was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Assessment adjust charge could not be parked",
        modeProcess: WorkflowProcessMode.Park,
      };

    const setLoading = () => setIsLoadingPark(false);

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };

  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge>,
    setLoading: () => void
  ) => {
    const { payload, actionSuccess, defaultFailedMessage, modeProcess } = props;

    const response = await postProcessAdjustCharge(modeProcess, payload);
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      notificationFormStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
  };

  /**
   * Handle Next button
   */
  const handleNextButton = async (data: any) => {
    setIsLoadingOnNext(true);
    let workflowDetail: any = {
      ...adjustChargeInitData?.WorkflowDetail,
      JournalMode: data?.WhichJournal?.JournalMode ?? 0,
      Journal_Number:
        data?.WhichJournal?.JournalSelected?.[0]?.Journal_Number ?? 0,
    };
    if (!isNil(data?.CreateJournal?.SupplementaryMode)) {
      workflowDetail.SupplementaryMode = data.CreateJournal.SupplementaryMode;
    }
    const sendSteps = pickBy(data, function (value, key) {
      if (
        adjustChargeKeysOfSendSteps.includes(key as EKeysOfStepsAdjustCharge)
      ) {
        return { [key]: value };
      }
    });
    if (sendSteps) {
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (key === EKeysOfStepsAdjustCharge.AdjustCharges) {
          workflowDetail[EKeysOfStepsAdjustCharge.AdjustCharges] = value ?? [];
        } else {
          workflowDetail[
            key as keyof DTO_Workflow_JournalAssessmentAdjustCharge
          ] = dataStep;
        }
      }
    }
    omitOfficerBasedOnStatus(workflowDetail);
    return handleSaveAndNext({
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    });
  };

  const processData = (data: any) => {
    let workflowDetail: any = {
      ...adjustChargeInitData?.WorkflowDetail,
      JournalMode: data?.WhichJournal?.JournalMode ?? 0,
      Journal_Number:
        data?.WhichJournal?.JournalSelected?.[0]?.Journal_Number ?? 0,
      SupplementaryMode: data?.CreateJournal?.SupplementaryMode ?? 0,
      CreateSupplementary: data?.CreateSupplementary,
    };
    const sendSteps = pickBy(data, function (value, key) {
      if (
        adjustChargeKeysOfSendSteps.includes(key as EKeysOfStepsAdjustCharge)
      ) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      const dataStep = { ...value };
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      if (key === EKeysOfStepsAdjustCharge.AdjustCharges) {
        workflowDetail[EKeysOfStepsAdjustCharge.AdjustCharges] = value ?? [];
      } else {
        workflowDetail[
          key as keyof DTO_Workflow_JournalAssessmentAdjustCharge
        ] = dataStep;
      }
    }
    omitOfficerBasedOnStatus(workflowDetail);
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  /**
   * Handle cancel process
   */
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessAdjustCharge,
        dataCancel: data,
        defaultSuccessMessage:
          "Assessment adjust charge was cancelled successfully.",
        defaultErrorMessage: "Assessment adjust charge cancel failed.",
      });
    } else {
      onClose();
    }
  };

  /**
   * handle confirm retain workflow process
   * @param payload
   */
  const handleConfirmRetainProcess = async (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge
  ) => {
    //set loading button and dialog
    setIsLoadingClose(true);

    //props send to process workflow
    const parkProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge> =
      {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Assessment adjust charge was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Assessment adjust charge could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

    const setLoading = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog(undefined);
    };

    //calling api process workflow
    await handleProcessWorkflow(parkProps, setLoading);
  };

  /**
   * handle close button
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };

  /**
   * handle finish workflow process
   * @param payload
   */
  const handleConfirmFinishProcess = (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge
  ) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "Assessment adjust charge will be created based on the information provided. Are you sure you want to submit?",
    });
  };

  /**
   * handle finish process
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge
  ) => {
    //props send to process workflow
    const finishProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge> =
      {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.SuccessMessage ??
              e?.Notification ??
              "Assessment adjust charge was finished successfully.",
            type: "success",
          };
          onClose();
          if (isFromActionList) {
            clearSelectedItems();
            eventEmitter.emit(CCGridEventType.RefreshOData);
          }

          !isRedirectManagePage && pushNotification(notificationContent);
          isRedirectManagePage &&
            history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.ID}`, {
              notification: notificationContent,
            });
        },
        defaultFailedMessage: "Assessment adjust charge could not be finished.",
        modeProcess: WorkflowProcessMode.Finish,
      };

    const setLoading = () => {};
    //calling api process workflow
    await handleProcessWorkflow(finishProps, setLoading);
  };

  /**
   * handle approve process
   */
  const handleApproveProcess = async (
    payload: DTO_Workflow_JournalAssessmentAdjustCharge
  ) => {
    //set loading button
    setIsLoadingApprove(true);

    //props send to process workflow
    const approveProps: IProcessWorkflow<DTO_Workflow_JournalAssessmentAdjustCharge> =
      {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.Notification ??
              e?.SuccessMessage ??
              "Assessment adjust charge was approved successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Assessment adjust charge could not be approved.",
        modeProcess: WorkflowProcessMode.Approve,
      };

    const setLoading = () => {
      setIsLoadingApprove(false);
    };
    //calling api process workflow
    await handleProcessWorkflow(approveProps, setLoading);
  };

  return (
    <CCFormStep
      ref={notificationFormStepRef}
      initialSteps={steps}
      initialValues={initialValue}
      listButtonId={listSubmitButton}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      onSubmit={handleSubmit}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={statusBadge}
          footerElement={
            <div className={"cc-dialog-footer-actions-right"}>
              {isShowParkButton(isFromActionList, isIncompleteMode) && (
                <Button
                  iconClass={isLoadingPark ? "fas fa-spinner fa-spin" : ""}
                  className={"cc-dialog-button"}
                  id={EListSubmitButton.Park}
                  onClick={renderProps.submitButton.onClick}
                  disabled={renderProps.nextButton.disabled || isLoadingPark}
                >
                  Park
                </Button>
              )}
              {!isReadOnly && isToBeApprovalMode && !isBeingApprovedMode && (
                <Button
                  iconClass={isLoadingSave ? "fas fa-spinner fa-spin" : ""}
                  disabled={renderProps.nextButton.disabled || isLoadingSave}
                  className={"cc-dialog-button"}
                  id={EListSubmitButton.Save}
                  onClick={renderProps.submitButton.onClick}
                >
                  Save
                </Button>
              )}
              {isShowCancelWorkflowButton && (
                <Button
                  className={"cc-dialog-button"}
                  disabled={
                    isLoadingApprove ||
                    isLoadingOnNext ||
                    isLoadingPark ||
                    isLoadingSave
                  }
                  id={EListSubmitButton.Cancel}
                  onClick={renderProps.submitButton.onClick}
                >
                  Cancel
                </Button>
              )}
              {isToBeApprovalMode && !isBeingApprovedMode && (
                <Button
                  themeColor="primary"
                  id={EListSubmitButton.Approve}
                  disabled={isLoadingApprove || renderProps.nextButton.disabled}
                  className={"cc-dialog-button"}
                  onClick={renderProps.submitButton.onClick}
                  iconClass={isLoadingApprove ? "fas fa-spinner fa-spin" : ""}
                >
                  Approve
                </Button>
              )}
              {!renderProps.prevButton.disabled && (
                <Button
                  className={"cc-dialog-button"}
                  themeColor="primary"
                  onClick={renderProps.prevButton.onClick}
                >
                  Previous
                </Button>
              )}
              {isToBeApprovalMode || isReadOnly ? (
                !renderProps.isLastStep && (
                  <Button
                    themeColor="primary"
                    id="cc-next-step-button"
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    className={"cc-dialog-button"}
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )
              ) : (
                <Button
                  themeColor="primary"
                  id={renderProps.nextButton.idButton}
                  disabled={isLoadingOnNext || renderProps.nextButton.disabled}
                  iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                </Button>
              )}
            </div>
          }
        />
      )}
    />
  );
}
