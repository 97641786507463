import { TDataFormField, TFormName } from "@common/stores/flexible-form/model";
import { FormSubmitClickEvent } from "@progress/kendo-react-form";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

type TState = any;
class FlexibleFormStore {
  private _dataRoute: Record<string, TState> = {};
  private _route: string = window.location.pathname;
  private _isSubmit: boolean = false;
  private _isCancelSubmit: boolean = false;
  private _isLoadingPage: boolean = false;
  private _onSubmitFlexible: Partial<
    Record<TFormName, (event: React.SyntheticEvent<any, Event>) => void>
  > = {};

  constructor() {
    makeAutoObservable(this);
  }
  onSubmitFlexible(formName: TFormName) {
    const eventSubmit = this._onSubmitFlexible[formName];
    return toJS(eventSubmit);
  }
  setOnSubmitFlexible = ({
    formName,
    eventSubmit,
  }: {
    formName: TFormName;
    eventSubmit: (event: React.SyntheticEvent<any, Event>) => void;
  }) => {
    runInAction(() => {
      this._onSubmitFlexible[formName] = eventSubmit;
    });
  };

  get isSubmit() {
    return toJS(this._isSubmit);
  }
  setIsSubmit = (status: boolean) => {
    runInAction(() => {
      this._isSubmit = status;
    });
  };

  get isCancelSubmit() {
    return toJS(this._isCancelSubmit);
  }
  setIsCancelSubmit = (status: boolean) => {
    runInAction(() => {
      this._isCancelSubmit = status;
    });
  };

  get isLoadingPage() {
    return toJS(this._isLoadingPage);
  }
  setIsLoadingPage = (status: boolean) => {
    runInAction(() => {
      this._isLoadingPage = status;
    });
  };

  get dataRoute() {
    return toJS(this._dataRoute);
  }
  setDataRoute = (route: string, dataFormItem: TState) => {
    runInAction(() => {
      this._dataRoute[route] = dataFormItem;
    });
  };

  //#region Listen submit event ========/
  waitForSubmit() {
    return new Promise((resolve, reject) => {
      if (this.isCancelSubmit) {
        reject("Cancelled Submit");
        return;
      }
      if (this.isSubmit) resolve("Submit");
    });
  }
  //#endregion Listen submit event =====/

  //#region Submit Form ========/
  submitFormGetData = async (formName: TFormName, event: any) => {
    try {
      if (this.onSubmitFlexible && event) {
        const submitAction = this.onSubmitFlexible(formName);
        if (submitAction) submitAction(event);
      }
      await this.waitForSubmit();
      this.setIsSubmit(false);
      return this.dataForm;
    } catch (e) {}
  };
  //#endregion Submit Form =====/

  //#region Handle Submit Form ========/
  middlewareSubmitForm = (
    event: FormSubmitClickEvent,
    otherCondition?: () => boolean
  ) => {
    this.setIsSubmit(false);
    if (
      (otherCondition && otherCondition()) ||
      (event.isModified && event.isValid)
    ) {
      this.setIsCancelSubmit(false);
      this.saveDataForm({ GeneralForm: event.values });
    } else {
      this.setIsCancelSubmit(true);
    }
  };
  //#endregion Handle Submit Form =====/

  //#region Get Set Data for form when submit ========/
  get dataForm() {
    return toJS(this._dataRoute[this._route]) as TDataFormField;
  }
  saveDataForm = (dataFormParam: TDataFormField) => {
    const prevDataForm = this.dataRoute[this._route];
    const newDataForm: TDataFormField = prevDataForm
      ? { ...prevDataForm, ...dataFormParam }
      : dataFormParam;
    this.setDataRoute(this._route, newDataForm);
    this.setIsSubmit(true);
  };
  //#endregion Get Set Data for form when submit =====/

  //#region Reset Data Form ========/
  resetDataForm = () => {
    runInAction(() => {
      this.setDataRoute(this._route, {});
    });
  };
  //#endregion Reset Data Form =====/
}
export const flexibleFormStoreInstance = new FlexibleFormStore();
const flexibleFormStoreContext = createContext(flexibleFormStoreInstance);
export const useFlexibleFormStore = () => {
  return useContext(flexibleFormStoreContext);
};
