import {
  hiddenSomeInputsTypes,
  hiddenSwitchTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/detail/config";
import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import {
  DTO_Entity_Details,
  EKeysOfSteps,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { requiredValidator } from "@common/utils/field-validators";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { lowerFirst, unionBy } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isLLS: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const typeChangeOfOwnership = valueGetter(`${EKeysOfSteps.Type}.Type`);
    const initAssociatedNames =
      getFieldValue(`_option.${fieldName.InitAssociatedNamesDetails}`) || [];
    const initRatePayers =
      getFieldValue(`_option.${fieldName.InitRatePayers}`) || [];
    const ratePayers = valueGetter(`${EKeysOfSteps.RatePayer}.Contacts`) || [];
    const associatedNames =
      valueGetter(EKeysOfSteps.AssociatedNamesDetails) || [];
    const currentNames = valueGetter(EKeysOfSteps.NamesGridData) || [];
    const selectedType = valueGetter(`${EKeysOfSteps.Type}._option.Type`);
    const isPreventReloadUseEffect = valueGetter(
      `${EKeysOfSteps.Details}._option.IsPreventReloadUseEffect`
    );

    //Get label
    const ratepayerLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Ratepayer
    );

    useEffectOnce(() => {
      if (!isPreventReloadUseEffect) {
        if (selectedType?.COOT_Retain_AssociatedNames) {
          handlePopulateAssociatedNames();
        }
        if (selectedType?.COOT_Retain_Occupier) {
          handlePopulateRatePayers();
        }
        onChange(`${EKeysOfSteps.Details}._option.IsPreventReloadUseEffect`, {
          value: true,
        });
      }
    });

    const handlePopulateAssociatedNames = () => {
      const newAssociatedNames = unionBy(
        associatedNames,
        initAssociatedNames,
        fieldName.PrimaryKey
      );
      onChange(EKeysOfSteps.AssociatedNamesDetails, {
        value: newAssociatedNames,
      });
      const newNames = unionBy(
        valueGetter(EKeysOfSteps.NamesGridData) || [],
        newAssociatedNames,
        fieldName.PrimaryKey
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };
    const handlePopulateRatePayers = () => {
      const newRatePayers = unionBy(
        ratePayers,
        initRatePayers,
        fieldName.PrimaryKey
      );
      onChange(`${EKeysOfSteps.RatePayer}.${fieldName.Names}`, {
        value: newRatePayers,
      });
      const newNames = unionBy(
        valueGetter(EKeysOfSteps.NamesGridData) || [],
        newRatePayers,
        fieldName.PrimaryKey
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };

    const handleRemoveAssociatedNames = () => {
      const initAssociatedIds = initAssociatedNames.map(
        (nameItem: DTO_Entity_Details) => nameItem.Entity_Id
      );
      const newAssociatedNames = associatedNames.filter(
        (nameItem: DTO_Entity_Details) =>
          !initAssociatedIds.includes(nameItem.Entity_Id)
      );
      onChange(EKeysOfSteps.AssociatedNamesDetails, {
        value: newAssociatedNames,
      });

      const newNames = currentNames.filter(
        (nameItem: DTO_Entity_Details) =>
          !initAssociatedIds.includes(nameItem.Entity_Id)
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };

    const handleRemoveRatePayers = () => {
      const initRatePayerIds = initRatePayers.map(
        (nameItem: DTO_Entity_Details) => nameItem.Entity_Id
      );
      const newRatePayers = ratePayers.filter(
        (nameItem: DTO_Entity_Details) =>
          !initRatePayerIds.includes(nameItem.Entity_Id)
      );
      onChange(`${EKeysOfSteps.RatePayer}.${fieldName.Names}`, {
        value: newRatePayers,
      });

      const newNames = currentNames.filter(
        (nameItem: DTO_Entity_Details) =>
          !initRatePayerIds.includes(nameItem.Entity_Id)
      );
      onChange(EKeysOfSteps.NamesGridData, {
        value: newNames,
      });
      onChange(`${EKeysOfSteps.Names}.${fieldName.isNeedToResetFormatted}`, {
        value: true,
      });
    };
    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <div className="cc-field cc-col-span-2">
            <CCLabel title="Reference" isMandatory={options.isLLS} />
            <Field
              name={nameOf("Reference")}
              component={CCInput}
              placeholder="Reference"
              readOnly={options?.isReadOnly}
              validator={
                !options?.isReadOnly && options.isLLS
                  ? requiredValidator
                  : undefined
              }
            />
          </div>

          <div className="cc-field">
            <CCLabel title="Effective date" />
            <Field
              name={nameOf("EffectiveDate")}
              format={DATE_FORMAT.DATE_CONTROL}
              component={CCDatePicker}
              disabled={options?.isReadOnly}
            />
          </div>
          {!hiddenSomeInputsTypes.includes(typeChangeOfOwnership) && (
            <>
              <div className="cc-field">
                <CCLabel title="Sale price" />
                <Field
                  name={nameOf("SalePrice")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Contract date" />
                <Field
                  name={nameOf("ContractDate")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  disabled={options?.isReadOnly}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Chattels" />
                <Field
                  name={nameOf("Chattels")}
                  component={CCCurrencyInput}
                  disabled={options?.isReadOnly}
                  min={0}
                />
              </div>
            </>
          )}

          <div className="cc-field cc-col-span-2">
            <CCLabel title="Comment" />
            <Field
              name={nameOf("Comment")}
              component={CCTextArea}
              rows={3}
              placeholder="Comment"
              readOnly={options?.isReadOnly}
            />
          </div>
        </div>

        <div className="cc-form-cols-3">
          {selectedType?.COOT_Display_Retain_AssociatedNames && (
            <div className="cc-field">
              <CCLabel title="Retain associated names" />
              <Field
                name={nameOf("RetainAssociatedNames")}
                component={CCSwitch}
                checked={getFieldValue("RetainAssociatedNames")}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("RetainAssociatedNames"), {
                    value: event.value,
                  });
                  if (event.value) {
                    handlePopulateAssociatedNames();
                  } else {
                    handleRemoveAssociatedNames();
                  }
                }}
              />
            </div>
          )}
          {!options?.isLLS &&
            !hiddenSwitchTypes.includes(typeChangeOfOwnership) && (
              <div className="cc-field">
                <CCLabel title="Retain lease details" />
                <Field
                  name={nameOf("RetainLeaseDetails")}
                  component={CCSwitch}
                  checked={getFieldValue("RetainLeaseDetails")}
                  disabled={options?.isReadOnly}
                />
              </div>
            )}
          {selectedType?.COOT_Display_Retain_Occupier && (
            <div className="cc-field">
              <CCLabel title={`Retain ${lowerFirst(ratepayerLabel)} details`} />
              <Field
                name={nameOf("RetainRatePayerDetails")}
                component={CCSwitch}
                checked={getFieldValue("RetainRatePayerDetails")}
                disabled={options?.isReadOnly}
                onChange={(event: SwitchChangeEvent) => {
                  onChange(nameOf("RetainRatePayerDetails"), {
                    value: event.value,
                  });
                  if (event.value) {
                    handlePopulateRatePayers();
                  } else {
                    handleRemoveRatePayers();
                  }
                }}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
);
