import { EKeysOfStepsNewTransaction } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import {
  checkIsMyExistingJournal,
  checkIsNewJournal,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/util";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { JournalStatus } from "@app/products/property/journals/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { State } from "@progress/kendo-data-query";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import {
  colJournals,
  dataWhichJournal,
  getJournalOdata,
  listTypeTurnOnSwitch,
} from "./config";

const nameOfJournal = nameOfFactory<VO_JournalList>();

const formValidator = (values: any) => {
  if (
    !values ||
    (!checkIsNewJournal(values.JournalMode) &&
      values.JournalSelected &&
      values.JournalSelected.length !== 1)
  ) {
    return "Must select one journal";
  }
  return undefined;
};

export const WHICH_JOURNAL_FORM_STEP = "JournalMode";
export const WhichJournalFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = ({
  setStepsVisible,
  formRenderProps,
  nameOf,
  resetAllStepsAfter,
  options = {},
}: IFormStepElement) => {
  const {
    isNoticeRunJournal = false,
    journalOptions = dataWhichJournal,
    showBringUpField = false,
    isReadOnly = false,
    newJournalKey = EKeysOfStepsNewTransaction.NewJournal,
    newSupplementary = EKeysOfStepsNewTransaction.NewSupplementary,
    journalTypeNumber = 0,
    journalStatus = JournalStatus.Incomplete,
  } = options;
  const { valueGetter, onChange, errors } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));

  const handleChangeStep = (journalMode: JournalMode) => {
    let newSteps = resetAllStepsAfter();
    if (setStepsVisible) {
      setStepsVisible(
        [
          {
            key: newJournalKey ?? EKeysOfStepsNewTransaction.NewJournal,
            visible: checkIsNewJournal(journalMode),
            isClearData: true,
          },
          {
            key:
              newSupplementary ?? EKeysOfStepsNewTransaction.NewSupplementary,
            visible: false,
            isClearData: true,
          },
        ],
        newSteps
      );
    }
  };
  const journalSelected = getFieldValue("JournalSelected");
  const journalMode = getFieldValue("JournalMode");

  const journalListState = useMemo(() => {
    if (isNil(journalTypeNumber) || isNil(journalStatus)) return undefined;
    const filterData = [];
    if (!isNil(journalTypeNumber)) {
      filterData.push({
        operator: "eq",
        field: "Journal_Type_Id",
        value: journalTypeNumber,
      });
    }
    if (!isNil(journalStatus)) {
      filterData.push({
        operator: "eq",
        field: "Journal_Status_Id",
        value: journalStatus,
      });
    }

    return {
      filter: {
        filters: filterData,
        logic: "and",
      },
    } as State;
  }, [journalStatus, journalTypeNumber]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Which journal?</label>
          <Field
            name={nameOf("JournalMode")}
            data={journalOptions}
            component={RadioGroup}
            onChange={(e: RadioGroupChangeEvent) => {
              onChange(nameOf("JournalSelected"), {
                value: [],
              });
              onChange(nameOf("JournalMode"), {
                value: e.value,
              });
              handleChangeStep(e.value);
            }}
            disabled={isReadOnly}
          />
        </div>

        {showBringUpField ? (
          <div className="cc-field">
            <label className="cc-label">Bring up journal on completion</label>
            <Field
              name={nameOf("BringUpJournal")}
              component={CCSwitch}
              defaultChecked={getFieldValue("BringUpJournal")}
            />
          </div>
        ) : null}
      </div>
      <div className="cc-form-cols-1">
        {isNoticeRunJournal && (
          <>
            <div className="cc-field">
              <CCLabel title="Only show journal not previously included on notices" />
              <Field
                name={nameOf("OnlyShowJournal")}
                component={CCSwitch}
                defaultChecked={
                  getFieldValue("OnlyShowJournal") ||
                  isShowWithNoticeType(listTypeTurnOnSwitch, valueGetter)
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Only include journals containing charges" />
              <Field
                name={nameOf("OnlyIncludeJournal")}
                component={CCSwitch}
                defaultChecked={
                  getFieldValue("OnlyIncludeJournal") ||
                  isShowWithNoticeType(listTypeTurnOnSwitch, valueGetter)
                }
              />
            </div>
          </>
        )}
        {!checkIsNewJournal(journalMode) ? (
          <div className="cc-field">
            <CCLabel
              title="Journals"
              isMandatory
              errorMessage={errors?.[nameOf("")]}
            />
            <CCGrid
              dataUrl={getJournalOdata(checkIsMyExistingJournal(journalMode))}
              itemPerPage={10}
              primaryField={nameOfJournal("Journal_Number")}
              state={journalListState}
              columnFields={colJournals}
              selectedRows={journalSelected}
              selectableMode="single"
              readOnly={isReadOnly}
              onSelectionChange={(dataItem: VO_JournalList[]) => {
                onChange(nameOf("JournalSelected"), {
                  value: [...dataItem],
                });
                !isNoticeRunJournal &&
                  onChange(
                    `${EKeysOfStepsNewTransaction.AssessmentJournal}.Journal_Number`,
                    {
                      value: dataItem?.[0]?.Journal_Number ?? 0,
                    }
                  );
              }}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
};
