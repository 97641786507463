import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";
interface ICreateAssessmentJournalCommonButtonProps {
  assessmentIDs?: number[];
  isDisabled?: boolean;
}
export const CreateAssessmentJournalCommonButton = observer(
  ({
    isDisabled,
    assessmentIDs = [],
  }: ICreateAssessmentJournalCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();

    return (
      <CCNavButton
        title="Create journal"
        disabled={isDisabled}
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Journal_Assessment_Add_Transaction,
              data: { assessmentIDs },
            },
          ]);
        }}
      />
    );
  }
);
