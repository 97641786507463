import { history } from "@/AppRoutes";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  getPermitTypeById,
  postSavePermitType,
} from "@app/products/local-laws/system-admin/permit-types/[id]/api";
import { LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE } from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import { PermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { isSuccessIdentityPacket } from "@common/apis/util";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";

export const SaveButtonSystemAdminPermitType = observer(() => {
  //#region STORE ========/
  const { submitFormGetData, isLoadingPage, setIsLoadingPage, saveDataForm } =
    useFlexibleFormStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { pushNotificationPortal } = useNotificationPortalStore();
  const isNew = useIsNew();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_PermitType,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });
  //#endregion HOOK =====/

  //#region METHOD ========/
  const handlePushNotification = (status: boolean, response?: any) => {
    setIsLoadingPage(false);
    if (status) {
      pushNotificationPortal(
        {
          title: "Permit type saved successfully.",
          type: "success",
        },
        true
      );
    } else {
      pushNotificationPortal({
        autoClose: false,
        title: "Save permit type failed.",
        type: "error",
        description: response?.data?.Errors ?? response?.statusText,
      });
    }
  };
  //#endregion METHOD =====/

  const handleClickButton = async (event: React.SyntheticEvent<any, Event>) => {
    const dataForm = await submitFormGetData("GeneralForm", event);
    const permitType = dataForm?.GeneralForm as PermitType;

    if (permitType) {
      setIsLoadingPage(true);

      const responseSave = await postSavePermitType(permitType);

      //#region load new data ========/
      if (
        isSuccessIdentityPacket(responseSave) &&
        responseSave?.data &&
        responseSave?.data?.ID
      ) {
        if (isNew) {
          const urlRedirect = `${LOCAL_LAWS_SYSTEM_ADMIN_PERMIT_TYPES_ROUTE}/${responseSave.data.ID}`;
          pushNotificationPortal({
            title: "Permit type saved successfully.",
            type: "success",
            route: urlRedirect,
            callBack() {
              history.replace(urlRedirect);
            },
          });
        }

        const responseGet = await getPermitTypeById(responseSave.data.ID);

        if (isSuccessIdentityPacket(responseGet)) {
          handlePushNotification(true);
          //#region set new data for form ========/
          saveDataForm({
            GeneralForm: responseGet.data.ReturnObj,
          });
          //#endregion set new data for form =====/
        } else {
          handlePushNotification(false, responseGet);
        }
      } else {
        handlePushNotification(false, responseSave);
      }
      //#endregion load new data =====/
    }
  };

  return (
    <CCNavButton
      title="Save"
      onClick={handleClickButton}
      disabled={
        isLoadingPage ||
        isLoadingPermission ||
        !checkPermissions([
          FormAction.CORE_ALLOW_SAVE,
          FormAction.CORE_ALLOW_EDIT,
        ])
      }
    />
  );
});
