export enum ePathLevel {
  Root = 0,
  Product = 1,
  Category1 = 2,
  Category2 = 3,
  Category3 = 4,
  Category4 = 5,
  Category5 = 6,
  Category6 = 7,
  Category7 = 8,
  Category8 = 9,
}

export enum eProductType {
  SystemInitialise = "SystemInitialise",
  Core = "Core",
  Contact = "Contact",
  Action = "Action",
  HealthManager = "HealthManager",
  CRS = "CRS",
  WasteWater = "WasteWater",
  Reports = "Reports",
  MailMerge = "MailMerge",
  Dashboards = "Dashboards",
  CustomerService = "CustomerService",
  HomePage = "HomePage",
  Complaints = "Complaints",
  Notices = "Notices",
  Streatrader_DONOTUSE = "Streatrader_DONOTUSE",
  SRS_DONOTUSE = "SRS_DONOTUSE",
  EPlanning = "EPlanning",
  WasteWaterSPI = "WasteWaterSPI",
  ELF = "ELF",
  DevApps = "DevApps",
  MaternalChildHealth = "MaternalChildHealth",
  Building = "Building",
  TownPlanning = "TownPlanning",
  LLPermits = "LLPermits",
  Animals = "Animals",
  CRSPortal = "CRSPortal",
  FSR = "FSR",
  CSM = "CSM",
  EHealth = "EHealth",
  StreetProtection = "StreetProtection",
  AppointmentManagementSystem = "AppointmentManagementSystem",
  EventManagementSystem = "EventManagementSystem",
  PortalCore = "PortalCore",
  AppointmentBookingSystem = "AppointmentBookingSystem",
  EventBookingSystem = "EventBookingSystem",
  FoodAct = "FoodAct",
  NSPR = "NSPR",
  Radiation = "Radiation",
  StandardsAndRegulation = "StandardsAndRegulation",
  DWCMS = "DWCMS",
  FleetBooking = "FleetBooking",
  CommunityAsset = "CommunityAsset",
  CommunityProperty = "CommunityProperty",
  LandManagement = "LandManagement",
  FakeProduct = "FakeProduct",
  Agent = "Agent",
  ThirdParty = "ThirdParty",
  DISRES = "DISRES",
  CM_DONOTUSE = "CM_DONOTUSE",
  CC = "CC",
  PAM = "PAM",
  ITSupport = "ITSupport",
  CRMS_HardRubbish = "CRMS_HardRubbish",
  CRMS_PhoneMessages = "CRMS_PhoneMessages",
  CRMS_Payroll = "CRMS_Payroll",
  CRSCentreAccess = "CRSCentreAccess",
  TrainingManager = "TrainingManager",
  TelephoneActivity = "TelephoneActivity",
  Asset = "Asset",
  Property = "Property",
  Payroll = "Payroll",
  Receipting = "Receipting",
  HumanResource = "HumanResource",
  GIS = "GIS",
  Infringement = "Infringement",
  Project = "Project",
  CouncilOnline = "CouncilOnline",
  SSMS = "SSMS",
  Records = "Records",
  OtherSystems = "OtherSystems",
  Hazard = "Hazard",
  Cemeteries = "Cemeteries",
  OpenOfficeCRM = "OpenOfficeCRM",
  Infringements = "Infringements",
}

export enum eDBRowState {
  Inactive = "Inactive",
  Active = "Active",
  Archive = "Archive",
}

export enum eMenuAction {
  Home = 0,
  //Favourites = 1,
  Favourites_List = 2,

  /* #region Search 10-19 */
  ApplicationSearch = 10,
  AdvancedSearch_New = 11,
  AdvancedSearch_List = 12,
  //AdvancedSearch_ListFavouriteSearches = 13,
  AdvancedSearch_Footprints = 14,
  AdvancedSearch_RunSearch = 15,
  /* #endregion */

  /* #region Assessments 20-30 */
  Assessment_Folder = 20,
  Assessment_Searches = 21,
  Assessment_List = 22,
  Assessment_Footprints = 23,
  Assessment_Processes = 24,
  Assessment_Active = 25,
  Assessment_Collections = 26,
  Assessment_Balances = 27,
  /* #endregion */

  Assessment_ReAllocationOfCredits = 31,
  Assessment_PropertyAddressExceptions = 32,
  Assessment_InstalmentPlanChange = 33,
  Assessment_InstalmentsToFull = 34,
  Assessment_UploadVictorianFireServiceLevyReportsToSRO = 35,

  /* #region Arrangements 41-50 */
  Arrangement_List = 41,
  Arrangement_New = 42,
  Arrangement_ProduceLetters = 43,
  Arrangement_ListLettersProduced = 44,
  Arrangement_Folder = 45, //VR CC 11/04/2022
  Arrangement_List_Active = 46,
  Arrangement_List_All = 47,
  Arrangement_List_InstalmentDue = 48,
  /* #endregion */

  /* #region Debt Recovery 51-60 */
  DebtRecovery_List = 51,
  DebtRecovery_ListCandidates = 52,
  DebtRecovery_ListActionsAndLetters = 53,
  DebtRecovery_ListPaymentsReceived = 54,
  DebtRecovery_ListExemptions = 55,
  DebtRecovery_Folder = 56, //VR CC 11/04/2022
  DebtRecovery_List_All = 57,
  DebtRecovery_ListActionsAndLetters_All = 58,
  DebtRecovery_ListActionsAndLetters_DebtRecoveries = 59,
  //5060-5063
  DebtRecovery_ListActionsAndLetters_Issued = 5060,
  DebtRecovery_ListActionsAndLetters_TobeIssuedForDebtRecoveries = 5061,
  DebtRecovery_ListPaymentsReceived_All = 5062,
  DebtRecovery_ListPaymentsReceived_Last = 5063,
  /* #endregion */

  /* #region Mailbox 60-69 */
  Mailbox_List = 60,
  Mailbox_Folder = 61, //VR CC 11/04/2022
  Mailbox_List_All = 62,
  /* #endregion */

  /* #region Master Properties 70-80 */
  MasterProperties_List = 70,
  /* #endregion */

  /* #region Financial Summaries 81-90 */
  FinancialSummaries_List = 81,
  FinancialSummaries_New = 82,
  FinancialSummaries_GenerateMenuItemForEachSummary = 83,
  FinancialSummaries_Run = 84,
  /* #endregion */

  /* #region Rebates 91-99 */
  RebateEntitlement_List = 91,
  RebateClaim_New = 92,
  RebateClaim_List = 93,
  Concession_Card_Holders = 94,
  Rebates_Folder = 95, //VR CC 11/04/2022
  RebateEntitlement_List_Exceptions = 96,
  RebateEntitlement_List_Exceptions_All = 97,
  RebateEntitlement_List_Exceptions_OnlyActive = 98,
  /* #endregion */

  /* #region Concession Cards 5080-5083*/
  Rebates_ConcessionCards = 5080,
  Rebates_ConcessionCards_All = 5081,
  Rebates_ConcessionCards_Active = 5082,
  Rebates_ConcessionCards_WithActiveEntitlements = 5083,
  /* #endregion */

  /* #region Supplementaries 100-120 */
  Supplementaries_Folder = 100,
  Supplementaries_List = 101,
  Supplementaries_New = 102,
  Supplementaries_GoToLastSupplementary = 103,
  Supplementaries_Valuations_by_Group = 105,
  Supplementaries_Import_Valuations = 106,
  Supplementaries_Create_from_VM2000 = 110,
  Supplementaries_Import_VM2000_General_Valuations = 111,
  Supplementaries_Import_AVO_General_Valuations = 112,
  Supplementaries_Import_SALIS_General_Valuations = 113,
  Supplementaries_Import_NSW_General_Valuations = 114,
  Supplementaries_Import_Tasmanian_Valuer_General_Valuations = 115,
  Supplementaries_List_All = 116,
  Supplementaries_List_CreatedByMe = 117,
  Supplementaries_List_GroupByRatingPeriod = 118,
  /* #endregion */

  /* #region Parcels 120-129 */
  Parcel_Folder = 120,
  Parcel_Generate_Locality_Street_Address_MenuItems = 121,
  Parcel_ShowLocality = 122,
  Parcel_ShowStreet = 123,
  Parcel_Footprints = 124,
  Parcel_SearchList = 125,
  /* #endregion */

  /* #region 130-139 */
  Title_Folder = 130,
  Title_Footprints = 131,
  Title_SearchList = 132,
  /* #endregion */

  /* #region Journals 140-150 */
  Journals_Folder = 140,
  Journals_Entry = 141,
  Journals_SearchList = 142,
  Journals_Authorise = 143,
  Journals_CreateSessionWithAuthorisedJournals = 144,
  Journals_Debtor_Control_Variances = 145,
  Journals_Transaction_Import = 146,
  Journals_TransactionsNotYetInJournals = 147,
  Journals_MyJournal = 148, //VR CC 11/04/2022
  Journals_NotYetInJournal = 147,
  Journals_TobeAuthorise = 149,
  Sessions_List = 150,
  /* #endregion */

  /* #region Journals 5600-5602 */
  Journals_TransactionsNotYetInJournals_AssessmentTransactions = 5600,
  Journals_TransactionsNotYetInJournals_DeferredDutyTransactions = 5601,
  Journals_TransactionsNotYetInJournals_CashlinkPayments = 5602,
  /* #endregion */

  /* #region Change of Ownership 160-169 */
  Change_of_Ownership_List = 160,
  Change_of_Ownership_New = 161,
  Change_of_Ownership_Documents = 162,
  Change_of_Ownership_Folder = 163, //VR CC 11/04/2022
  Change_of_Associated_Name_Address = 164, //VR CC 11/04/2022
  Change_of_Name_Address = 165, //VR CC 11/04/2022
  Transfer_of_Ownership_Full = 166, //VR CC 11/04/2022
  Transfer_of_Ownership_Partial = 167, //VR CC 11/04/2022
  Change_of_Ownership_Lease_Transfers = 168,
  /* #endregion */
  /* #region Change of Ownership 5700-5701 */
  Change_of_Ownership_Folder_Notice_Of_Sales_TobeProcessed = 5700,
  Change_of_Ownership_Folder_Notice_Of_Sales_ListbyImportFile = 5701,
  /* #endregion */

  /* #region Name and Addresses 170-174 */
  NameAddressRegister_List_Changes = 170,
  NameAddressRegister_List_Pending_Changes = 171,
  /* #endregion */
  //ACTLeaseTransfer_List = 175,
  //ACTLeaseTransfer_List_Pending = 176,

  /* #region Certificates 180-189 */
  Certificate_Folder = 180,
  Certificate_New = 181,
  Certificate_List_Active = 182,
  Certificate_Generate_List_for_Nominated_Type = 183,
  Certificate_Footprints = 184,
  Certificate_List_eCertificate_Requests = 185,
  Certificate_List = 186,
  Certificate_List_Active_By_Type = 187, //VR CC 11/04/2022
  /* #endregion */
  /* #region Certificates 5900-5903 */
  Certificate_List_eCertificate_Requests_Cancelled = 5900,
  Certificate_List_eCertificate_Requests_Completed = 5901,
  Certificate_List_eCertificate_Requests_InError = 5902,
  Certificate_List_eCertificate_Requests_InProgress = 5903,
  /* #endregion */

  /* #region Deferred Duty 190-199 */
  DeferredDuty_Folder = 190,
  DeferredDuty_List = 191,
  DeferredDuty_New_Account = 192,
  DeferredDuty_Raise_Interest = 193,
  DeferredDuty_Raise_Interest_for_Single_Account = 194,
  DeferredDuty_List_Statement_Runs = 195,
  //DeferredDuty_Favourites = 196,
  DeferredDuty_Footprints = 197,
  DeferredDuty_List_All = 198,
  DeferredDuty_List_UnpaidAccount = 199,
  /* #endregion */

  //LeaseAdvice_List = 200,
  //LeaseAdvice_New = 201,
  /* #region Meters 210-230 */
  Meter_Generate_Menu_Items_for_each_Utility = 210,
  Meter_List = 211,
  Meter_New = 212,
  //Meter_Favourites = 213,
  Meter_Go_to_Meter = 214,
  Meter_Enter_Meter_Reading = 215,
  Meter_Schedule_Special_Reading = 216,
  Meter_Special_Reads = 217,
  Meter_Generate_Menu_Items_for_Standard_Searches = 222,
  Meter_Generate_Menu_Items_for_Processes = 223,
  Meter_Footprints = 224,
  Meter_Reading_Devices = 225,
  Meter_Reading_Routes = 226,
  Meter_Create_Journal_with_Meter_Usages = 227,
  Meter_Last_Reading_Details = 228,
  Meter_Readings_To_Be_Processed = 229,
  /* #endregion */

  /* #region Schemes 300-309 */
  Schemes = 300,
  Schemes_List = 301,
  Schemes_Generate_Menu_Item_to_List_Accounts_for_Scheme_Type = 302,
  //Schemes_List_Scheme_Accounts_for_Scheme_Type = 302,
  Schemes_New_Scheme_Account_Journal = 303,
  Schemes_Import_New_Accounts_into_Scheme = 304,
  Schemes_NoticeRuns = 305,
  Schemes_InterestRun = 306,
  Schemes_Generate_Menu_Items_for_Processes = 307,
  Schemes_Footprints = 308,
  Schemes_List_All = 309,
  Schemes_List_IncludeInactive = 310,
  Schemes_List_IncludeActualCost = 311,
  Schemes_List_OnlyActive = 312,
  /* #endregion */

  /* #region Entities 320-329 */
  Entities_List = 320,
  Entities_VoterList = 321,
  Entities_PotentialDuplicates = 322,
  Entities_Folder = 323,
  Entities_PotentialDuplicates_NameStreetLocality = 324,
  Entities_PotentialDuplicates_NameStreetLocality_All = 325,
  Entities_PotentialDuplicates_NameStreetLocality_Active = 326,
  Entities_PotentialDuplicates_SameName = 327,
  Entities_PotentialDuplicates_SameName_All = 328,
  Entities_PotentialDuplicates_SameName_Active = 329,
  /* #endregion */

  /* #region Register 330-349 */
  Register_Generate_Menu_Items_for_each_Registers = 330,
  Register_List = 331,
  Register_GotoAccount = 332,
  Register_Footprints = 333,
  /* #endregion */

  /* #region Collections 340-349 */
  Collections_List = 340,
  Collections_New = 341,
  Collections_Generate_Menus_to_List_Collections_by_Type = 342,
  Collections_Show_Collection = 343,
  Collections_Folder = 344,
  /* #endregion */

  /* #region Reports 400-409 */
  Reports_List = 400,
  Reports_GenerateMenuItemsReportsByGroup = 401,
  Reports_Folder = 402,
  /* #endregion */

  /* #region Notice Groups Processes 420-439     //VR CC 11/04/2022 */
  Notice_Groups_Folder = 420, //VR CC 11/04/2022
  Notice_Groups_ACT_Housing = 421,
  Notice_Groups_DHA_Owns_And_Pays_Rates = 422,
  Notice_Groups_ECHO_Housing = 423,
  /* #endregion */

  //NoticeProduction_Folder = 410,
  //NoticeProduction_Run_RatesProductionExe = 411,
  //NoticeProduction_GenerateMenuItemsForEachNoticeGroup = 412,
  //NoticeProduction_ListNoticeRuns = 413,
  Process_Folder = 501,
  Process_Housekeeping = 502,
  Process_Reallocation_Of_Credit = 503,
  Process_Write_Off_Postponed_Balances = 504,
  Process_Write_Off_Small_Balances = 505,
  Audit_GenerateMenusByTableField = 600,
  Audit_Folder = 601,
  Audit_Folder_List = 602,
  Audit_ShowApplicationLogFile = 610,
  Options_List = 700,
  //Support_Folder = 800,
  Support_Documentation = 801,
  Support_Airtime = 802,
  Support_ContactOpenOffice = 803,
  Support_About = 804,
  OpenAssociatedFile = 900,
  OpenAssociatedUri = 901,

  /* #region Tasks 1001-1100 */
  Task_List = 1001,
  Task_ActionLetters = 1002,
  Task_Folder = 1003, //VR CC 11/04/2022
  Task_ParkedList = 1004,
  Task_ToBeApprovedList = 1005,
  Task_ApprovedList = 1006,
  Task_RejectedList = 1007,
  Task_CancelledList = 1008,
  Task_BeingApprove = 1009,
  Task_MyApprovals = 1010,
  TaskSchedule_Folder = 1050,
  TaskSchedule_List = 1051,
  /* #endregion */

  /* #region Charge Runs 1101-1120 */
  ChargeRun_Options = 1101,
  ChargeRun_Create = 1102,
  ChargeRun_Journals = 1103,
  ChargeRun_GoToLast = 1104,
  ChargeRun_List = 1105,
  Charge_And_Notice_Runs_Folder = 1106, //VR CC 11/04/2022
  ChargeRun_PICChargeRun_List = 1107,
  /* #endregion */

  /* #region Notice Production 1201-1299 */
  NoticeProduction_CreateRun = 1201,
  NoticeProduction_GoToLastRun = 1202,
  NoticeProduction_ListRuns = 1203,
  NoticeProduction_Run_RatesProductionExe = 1299,
  /* #endregion */

  /* #region Options 2001-2050 */
  Options_RatingPeriods = 2001,
  Options_TransactionTypes = 2002,
  Options_Levies = 2003,
  Options_InterestRates = 2004,
  Options_LevyRates = 2005,
  Options_DiscountTypes = 2006,
  Options_InstalmentPlans = 2007,
  Options_RebateTypes = 2008,
  Options_FinancialAreas = 2009,
  Options_FinancialGroups = 2010,
  Options_ChargeRunTypes = 2011,
  Options_GeneralLedgerAccountTypes = 2012,
  /* #endregion */

  /* #region Deferments 2500-2550    //VR CC 11/04/2022 */
  Deferments_Folder = 2501, //VR CC 11/04/2022
  Deferments_List = 2502,
  Deferments_List_All = 2503,
  Deferments_List_Active = 2504,
  /* #endregion */

  /* #region Compliance 2551-2570    //VR CC 11/04/2022 */
  Compliance_Folder = 2551, //VR CC 11/04/2022
  Compliance_All = 2552,
  Compliance_ActiveWithAssessment = 2553,
  Compliance_AllWithoutAssessment = 2554,
  Compliance_ActiveWithoutssessment = 2555,
  Compliance_LandTaxExemption = 2556,
  Compliance_Candidates = 2557,
  Compliance_EntitiesWithMultipleExemptions = 2558,
  Compliance_EntitiesWithMultipleExemptions_All = 2559,
  Compliance_EntitiesWithMultipleExemptions_NotUnderInvestigation = 2560,
  Compliance_LandRent = 2561,
  Compliance_ActionsAndLetters = 2562,
  Compliance_ActionsAndLetters_AllDocuments = 2563,
  Compliance_ActionsAndLetters_DocumentsToBeIssued = 2564,
  Compliance_ActionsAndLetters_AllDocuments_All = 2565,
  Compliance_ActionsAndLetters_DocumentsToBeIssued_All = 2566,
  /* #endregion */

  /* #region Fire Prevention 2571-2590   //VR CC 11/04/2022 */
  Fire_Prevention_Folder = 2571, //VR CC 11/04/2022
  Fire_Prevention_List_Current = 2572, //VR CC 11/04/2022
  Fire_Prevention_List_Historical = 2573, //VR CC 11/04/2022
  Fire_Prevention_List_Current_HazIdCases = 2574,
  Fire_Prevention_List_Current_Inspections = 2575,
  Fire_Prevention_List_Current_PropertiesToReinspect = 2576,
  Fire_Prevention_List_Current_OnHoldCases = 2577,
  Fire_Prevention_List_Current_Notices = 2578,
  Fire_Prevention_List_Current_Infringements = 2579,
  Fire_Prevention_List_Current_WorkOrders = 2580,
  Fire_Prevention_List_Current_AssignContractors = 2581,
  Fire_Prevention_List_Current_NotifyContractors = 2582,
  Fire_Prevention_List_Current_RecordWorkComplete = 2583,
  //Fire Prevention 6300-6309
  Fire_Prevention_List_Historical_HazIdCase = 6300,
  Fire_Prevention_List_Historical_Inspections = 6301,
  Fire_Prevention_List_Historical_PropertiesToReinspect = 6302,
  Fire_Prevention_List_Historical_OnHoldCases = 6303,
  Fire_Prevention_List_Historical_Notices = 6304,
  Fire_Prevention_List_Historical_Infringements = 6305,
  Fire_Prevention_List_Historical_WorkOrders = 6306,
  Fire_Prevention_List_Historical_AssignContractors = 6307,
  Fire_Prevention_List_Historical_NotifyContractors = 6308,
  Fire_Prevention_List_Historical_RecordWorkComplete = 6309,
  /* #endregion */

  /* #region PIC 2591-3010   //VR CC 11/04/2022 */
  PIC_Folder = 2591, //VR CC 11/04/2022
  PIC_List = 2592, //VR CC 11/04/2022
  PIC_Active = 2593,
  /* #endregion */

  /* #region CentreLink Customer Confirmation 3011-3030   //VR CC 11/04/2022 */
  CentreLink_Customer_Confirmation_Folder = 4000,
  CentreLink_View_Request_File = 4001,
  CentreLink_View_Responses = 4002,
  /* #endregion */

  /* #region Settings Menu */
  /* #region Settings Menu Categories */
  SettingsCategory_Menu = 3000,
  SettingsCategory_Assessment = 3001,
  SettingsCategory_Certificate = 3002,
  SettingsCategory_ChangeOfOwnership = 3003,
  SettingsCategory_CollectionTypes = 3004,
  SettingsCategory_DigitalMailbox = 3005,
  SettingsCategory_Entity = 3006,
  SettingsCategory_Enquiry = 3007,
  SettingsCategory_GIS = 3008,
  SettingsCategory_Integration = 3009,
  SettingsCategory_LeaseTypes = 3010,
  SettingsCategory_Meter = 3011,
  SettingsCategory_Notice = 3012,
  SettingsCategory_Organisation = 3013,
  SettingsCategory_Options = 3014,
  SettingsCategory_Parcel = 3015,
  SettingsCategory_Register = 3016,
  SettingsCategory_Report = 3017,
  SettingsCategory_Scheme = 3018,
  SettingsCategory_Title = 3019,
  SettingsCategory_Tasks = 3020,
  SettingsCategory_Folder = 3021, //VR CC 11/04/2022
  SettingsCategory_DocumentTemplates = 3022,
  SettingsCategory_Category = 3023,
  SettingsCategory_DeferredDuty = 3024,
  SettingsCategory_Journal = 3025,
  SettingsCategory_Workflows = 3026,
  SettingsCategory_Documents = 3027,
  /* #endregion */
  /* #endregion */

  Entities_LRSOwner = 5800,
}
