import { DTO_Amalgamate_AssessmentLOVs } from "@app/products/property/assessments/components/form-steps/amalgamate-assessment/model";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";

export const DetailsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfLovs = nameOfFactory<DTO_Amalgamate_AssessmentLOVs>();

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
      isToBeApprovalMode: false,
      workflowDraftId: 0,
    },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    return (
      <>
        <section className="cc-field-group">
          {options?.isToBeApprovalMode && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Workflow instructions" />
                <Field
                  name={nameOf("Instructions")}
                  rows={3}
                  component={CCTextArea}
                  placeholder="Workflow instructions"
                  readOnly
                />
              </div>
            </div>
          )}
        </section>

        <OfficerAndOfficerRegion
          formRenderProps={formRenderProps}
          nameOf={nameOf}
          isDisabled={options?.isReadOnly || options?.workflowDraftId}
        />

        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <CCLabel title="Applicant name" />
              <Field
                name={nameOf("ApplicantName")}
                placeholder={"Applicant name"}
                component={CCInput}
                readOnly={options?.isReadOnly}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reason for change" isMandatory />
              <Field
                name={nameOf("ReasonId")}
                placeholder="Reason for change"
                component={CCSearchComboBox}
                disabled={options?.isReadOnly}
                data={getFieldValue(nameOfLovs("ReasonForCreation")) ?? []}
                textField="Name"
                dataItemKey="Code"
                isUseDefaultOnchange
                validator={!options?.isReadOnly ? requiredValidator : undefined}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Reference" />
              <Field
                name={nameOf("Reference")}
                placeholder="Reference"
                component={CCInput}
                maxLength={100}
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
