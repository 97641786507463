import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

interface ICreateDefermentCommonButtonProps {
  recordID?: number;
  isDisabled?: boolean;
  isRedirectManagePage?: boolean;
}

export const CreateDefermentCommonButton = observer(
  ({
    recordID,
    isDisabled,
    isRedirectManagePage,
  }: ICreateDefermentCommonButtonProps) => {
    const { setListDialog } = usePropertyWorkflowStore();
    return (
      <CCNavButton
        title="Create Deferment"
        onClick={() => {
          setListDialog([
            {
              type: WorkflowTypes.Create_Deferment,
              data: {
                selectedAssessmentId: recordID,
                isRedirectManagePage: isRedirectManagePage,
              },
            },
          ]);
        }}
        disabled={isDisabled}
      />
    );
  }
);
